<template>
  <div class="menuPage">
    <el-image style="width: 65px;height: 24px;"
      :src="src"
    ></el-image>
    <span class="span16" style="margin-left: 80px;">新一代AI数字化平安校园引领者</span>
    <el-menu
      :router="true"
      :default-active="$route.path"
      mode="horizontal"
      text-color="#000000"
      active-text-color="#005699"
    >
      <el-menu-item index="/" style="font-size: 18px;border-bottom: none;">
        <span slot="title">首页</span>
      </el-menu-item>
      <el-menu-item index="/product" style="font-size: 18px;border-bottom: none;">
        <span slot="title">产品介绍</span>
      </el-menu-item>
      <el-menu-item index="/case" style="font-size: 18px;border-bottom: none;">
        <span slot="title">项目案例</span>
      </el-menu-item>
      <el-menu-item index="/news" style="font-size: 18px;border-bottom: none;">
        <span slot="title">新闻动态</span>
      </el-menu-item>
      <el-menu-item index="/recruit" style="font-size: 18px;border-bottom: none;">
        <span slot="title">校园招募</span>
      </el-menu-item>
      <el-menu-item index="/about" style="font-size: 18px;border-bottom: none;">
        <span slot="title">关于我们</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  data () {
    return {
      src: require('../../assets/images/logo1.png')
    }
  },
  methods: {
    toHome () {
      this.$router.push({ name: 'HomePage' })
    }
  }
}
</script>

<style scopde>
  .menuPage {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }
  .iconSize {
    width: 5em!important;
    height: 3.5em!important;
  }
  .menuPage {
    border-bottom: none!important;
  }
  .el-menu--horizontal {
    border-bottom: none!important;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none!important;
  }
  .titleP {
    font-size: 20px;
    color: #0D5698;
  }
</style>
