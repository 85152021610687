import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeLayout from '../components/layout/HomeLayout.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: (resolve) => require(['@/views/home/home'], resolve),
        meta: { title: '首页' }
      },
      {
        path: '/product',
        name: 'Product',
        component: (resolve) => require(['@/views/home/product'], resolve),
        meta: { title: '产品介绍' }
      },
      {
        path: '/case',
        name: 'Case',
        component: (resolve) => require(['@/views/home/case'], resolve),
        meta: { title: '项目案例' }
      },
      {
        path: '/news',
        name: 'News',
        component: (resolve) => require(['@/views/home/news'], resolve),
        meta: { title: '新闻动态' }
      },
      {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: (resolve) => require(['@/views/home/newsDetail'], resolve),
        meta: { title: '新闻动态' }
      },
      {
        path: '/recruit',
        name: 'Recruit',
        component: (resolve) => require(['@/views/home/recruit'], resolve),
        meta: { title: '校园招募' }
      },
      {
        path: '/about',
        name: 'About',
        component: (resolve) => require(['@/views/home/about'], resolve),
        meta: { title: '关于我们' }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
