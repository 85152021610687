<template>
  <el-container class="container">
    <el-header>
      <Sidebar />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <el-footer height="auto" style="padding: 0">
      <div class="footDiv mtp50">
        <div class="flexCol" style="align-items: center;">
          <p class="p40-fff">智慧校园</p>
          <img src="../../assets/images/ercode1.png" style="width: 170px;height: 170px;">
          <p class="p16-fff">公众号</p>
        </div>
        <div class="footMiddle">
          <div class="p14-fff fl-row-center">
            <div class="mrt50">总部地址：</div>
            <div>长沙市雨花区经济开发区振华路智庭园12栋801、802、803房</div>
          </div>
          <div class="footMiddleBox">
            <div class="p14-fff mrt50 textLft">联系方式：</div>
            <div class="flexCol">
              <span class="p14-fff">刘经理 18075197982</span>
              <span class="p14-fff mtp15">彭经理 15774032589</span>
            </div>
          </div>
          <div class="footMiddleBox">
            <div class="p14-fff mrt50 textLft">邮箱地址：</div>
            <div class="flexCol">
              <span class="p14-fff">pengxiaolan@hnzlyd.com</span>
            </div>
          </div>
        </div>
        <div class="flexCol" style="align-items: center;">
          <img src="../../assets/images/parentCode.png" style="width: 110px;height: 110px;" class="mtp50">
          <p class="p14-fff">家长端</p>
          <img src="../../assets/images/teacherCode.png" style="width: 110px;height: 110px;">
          <p class="p14-fff">教师端</p>
        </div>
      </div>
      <div class="span16 fl-row-center-center" style="padding: 10px 0;">
        <div class="mrt50">版权所有：</div>
        <div style="text-decoration: underline;cursor: pointer" @click="toWeb">湘ICP备2021012765号-1</div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Sidebar from '../layout/Sidebar'

export default {
  name: 'HomeLayout',
  components: {
    Sidebar
  },
  created () {

  },
  methods: {
    toWeb () {
      window.open('https://beian.miit.gov.cn', '_blank')
    }
  }
}
</script>
<style scoped>
  .container {
    height: 100%;
    /*overflow-y: scroll;*/
    min-width: 1400px;
  }
  .container /deep/ .el-header {
    position: absolute;
    top: 0;
    z-index: 2;
    padding: 0 80px;
    min-width: 1400px;
  }
  .container /deep/ .el-main {
    margin: 60px 0 0 0;
    padding: 0!important;
    /*height: calc(100% - 175px);*/
    overflow: hidden;
  }
  .footDiv {
    width: 100%;
    /*background: #1A90E8;*/
    display: flex;
    justify-content: space-around;
    background: url("../../assets/images/bg.jpg");
  }
  .footMiddle {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    justify-content: space-around
  }
  .footMiddleBox {
    display: flex;
    width: 100%;
  }
</style>
