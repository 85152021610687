import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import less from 'less'
import '@/assets/icons'
import SvgIcon from '@/components/svgIcon'
import BaiduMap from 'vue-baidu-map'

import '@/assets/styles/index.less'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(less)
Vue.use(BaiduMap, {
  ak: 'eAt9A9Ajis3OCh4ujGnOsix1sZIBdGfr'
})
Vue.component('svg-icon', SvgIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
